import React from 'react';
import MyStack from '../components/my_page/stack/MyStack.tsx';
import './tecnologias.css';
const Tecnologias = () => {
	return (
		<>
			<div className="tecnologias-section">
				<h1>Tecnologías</h1>

				<MyStack />
				<h3>En resumen:</h3>
				<ul>
					<li>Desarrollo Movil</li>
					<ul>
						<li>Dart</li>
						<li>Flutter</li>
					</ul>
					<li>Desarrollo Web</li>
					<ul>
						<li>Js - TypeScript - Css - Sass</li>
					</ul>
					<li>Web Apps</li>
					<ul>
						<li>React</li>
						<li>Flutter</li>
					</ul>
					<li>APIs</li>
					<ul>
						<li>Node.js</li>
						<li>Express.js</li>
						<li>Mongoose.js</li>
						<li>MongoDB</li>
					</ul>
				</ul>
			</div>
		</>
	);
};

export default Tecnologias;
