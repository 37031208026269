import React from 'react';
import './contacto.css';
const Contacto = () => {
	return (
		<div className="contact-section">
			<div className="card-glass">
				<h1>Mi información de contacto:</h1>
				<p>Con gusto atenderé su solicitud de cotización o cualquier consulta relacionada con sus proyectos.</p>
				<p>Correo: <a className="link-to" href="mailto:amir.velasquez1@gmail.com">amir.velasquez1@gmail.com</a></p>
				<p>X: <a className="link-to" href="https://x.com/NoSoyAmir">@NoSoyAmir</a></p>
				
			</div>
		</div>
	);
}; 

export default Contacto;
