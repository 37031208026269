import React from 'react';
import './Landpage.css';
import amir_image from '../assets/images/me_no_bkg.png';
import { useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from '@tsparticles/slim'; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { Container } from 'react-dom';
import MyStack from '../components/my_page/stack/MyStack.tsx';
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.
interface Props {
	isMobile: boolean;
}

const Landpage = (isMobile: Props) => {
	const [init, setInit] = useState(false);

	// this should be run only once per application lifetime
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
			// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
			// starting from v2 you can add only the features you need reducing the bundle size
			//await loadAll(engine);
			//await loadFull(engine);
			await loadSlim(engine);
			//await loadBasic(engine);
		}).then(() => {
			setInit(true);
		});
	}, []);

	const particlesLoaded = (container) => {
		// console.log(container);
	};

	const options = useMemo(
		() => ({
			background: {
				color: {
					value: '#000000FF'
				}
			},
			fpsLimit: 120,
			interactivity: {
				events: {
					onClick: {
						enable: true,
						mode: 'push'
					},
					onHover: {
						enable: true,
						mode: 'repulse'
					}
				},
				modes: {
					push: {
						quantity: 10
					},
					repulse: {
						distance: 200,
						duration: 0.4
					}
				}
			},
			particles: {
				color: {
					value: '#ffffff'
				},
				links: {
					color: '#ffffff',
					distance: 150,
					enable: true,
					opacity: 0.5,
					width: 1
				},
				move: {
					direction: 'none',
					enable: true,
					outModes: {
						default: 'bounce'
					},
					random: true,
					speed: 3,
					straight: false
				},
				number: {
					density: {
						enable: true
					},
					limit: {
						mode: 'delete',
						value: 200
					},
					value: 90
				},
				opacity: {
					value: 0.5
				},
				shape: {
					type: 'circle'
				},
				size: {
					value: { min: 1, max: 5 }
				}
			},
			detectRetina: true
		}),
		[]
	);

	if (init) {
		return (
			<>
				<Particles
					className="particles-js-css"
					style={{ zIndex: 0 }}
					id="tsparticles"
					particlesLoaded={particlesLoaded}
					options={options}
				/>
				<main>
					<div className="name-and-image">
						<h1>Amir Velasquez Aburdene</h1>
						<img
							width={isMobile ? 528 / 2 : 490}
							height={isMobile ? 560 / 2 : 400}
							className="amir-img"
							src={amir_image}
							alt="mi foto"
						/>
						<p>Desarrollador Web y Mobile</p>
					</div>
					<div className="about-me">
						<h2>Sobre mi:</h2>
						<p>
							Soy un desarrollador web{' '}
							<span className="text-golden">especializado en backend</span>, complementado
							por sólidos conocimientos en <span className="text-green">frontend</span> y
							desarrollo de<span className="text-violet"> aplicaciones móviles</span>.
							Tengo experiencia en la publicación de aplicaciones en Google Play Store y
							Apple App Store, de igual manera en el{' '}
							<span className="text-green">mantenimiento</span> y actualización de
							aplicaciones tanto web y moviles como de{' '}
							<span className="text-violet">APIs</span>. Esto me permite llevar proyectos
							desde la conceptualización hasta su lanzamiento exitoso, asegurando su
							óptimo funcionamiento a lo largo del tiempo.
						</p>
					</div>
					<div className="stack">
						<h2>Mi Stack:</h2>
						<MyStack />
					</div>
					{/* <script
						defer={true}
						about="limit particle instances"
						src="./limitp.js"
						crossOrigin="anonymous"
					></script>  */}
					<script type="module" src="./limitp.js" defer={true}></script>
				</main>
			</>
		);
	}

	return (
		<>
			<p>Cargando...</p>
		</>
	);
};

export default Landpage;
