import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
	return (
		<nav>
			<Link className="nav-main-title" to="/">
				Sobre Mi
			</Link>
			<ul>
				<li>
					<NavLink className="nav-title" to="my-stack">
						Tecnologias
					</NavLink>
				</li>
				<li>
					<NavLink className="nav-title" to="proyectos">
						Proyectos
					</NavLink>
				</li>
				<li>
					<NavLink className="nav-title" to="contacto">
						Contacto
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
