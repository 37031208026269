import logo from './logo.svg';
import './App.css';
import Navbar from './components/my_page/navbar/Navbar.tsx';
import { Route, Routes } from 'react-router-dom';
import Landpage from './pages/Landpage.tsx';
import Tecnologias from './pages/Tecnologias.tsx';
import Proyectos from './pages/Proyectos.tsx';
import Contacto from './pages/Contacto.tsx';
import { useEffect, useState } from 'react';

function App() {
	const [width, setWidth] = useState(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 768;
	return (
		<div className="App">
			<>
				<Navbar />
				<Routes>
					<Route path="/" element={<Landpage isMobile={isMobile} />} />
					<Route path="/my-stack" element={<Tecnologias />} />
					<Route path="/proyectos" element={<Proyectos />} />
					<Route path="/contacto" element={<Contacto />} />
				</Routes>
			</>
		</div>
	);
}

export default App;
