import React from 'react';
import { useState } from 'react';
import { RowsPhotoAlbum } from 'react-photo-album';
import 'react-photo-album/rows.css';
import expressImg from './logo/express.png';
import reactImg from './logo/react.png';
import jsImg from './logo/js.png';
import mongoImg from './logo/mongo.png';
import nodeImg from './logo/node.png';
import sassImg from './logo/sass.png';
import tsImg from './logo/ts.png';
import cssImg from './logo/css.png';
import flutterImg from './logo/flutter.png';
import dartImg from './logo/dart.png';
import gitImg from './logo/git.png';
import './stack.css';
function MyStack() {
	const photos = [
		{ src: dartImg, width: 100, height: 100, alt:"dart logo" },
		{ src: flutterImg, width: 100, height: 100,alt:"flutter logo"  },
		{ src: jsImg, width: 100, height: 100,alt:"javascript logo"  },
		{ src: tsImg, width: 100, height: 100,alt:"typescript logo"  },
		{ src: cssImg, width: 100, height: 100,alt:"css logo"  },
		{ src: sassImg, width: 100, height: 100,alt:"sass logo"  },
		{ src: reactImg, width: 100, height: 100, alt:"react logo"  },
		{ src: nodeImg, width: 100, height: 100, alt:"node.js logo"  },
		{ src: expressImg, width: 100, height: 100, alt:"express.js logo"  },
		{ src: mongoImg, width: 100, height: 100, alt:"mongoDB logo"  },
		{ src: gitImg, width: 100, height: 100, alt:"git logo"  }
	];
	return (
		<>
			<RowsPhotoAlbum defaultContainerWidth={100} spacing={50} photos={photos} />
			<br />
			<div className="card-glass">
				<h3>Desarrollo de apps moviles</h3>
				<p>
					Gracias al potente lenguaje Dart, Flutter permite desarrollar aplicaciones
					multiplataforma de manera ágil y visualmente impactante. Con una única base de
					código, se puede crear interfaces de usuario fluidas y nativas tanto para iOS
					como para Android, reduciendo significativamente el tiempo de desarrollo y
					garantizando una experiencia de usuario excepcional.
				</p>
				<h3>Desarrollo de web apps (MERN)</h3>
				<p>
					React, Sass y TypeScript forman un trío inigualable en el desarrollo web
					moderno. React, una biblioteca JavaScript de interfaz de usuario, permite crear
					componentes reutilizables y eficientes que facilitan la construcción de
					aplicaciones web interactivas. Typescript ayuda a preveniry detectar bugs en el
					desarrollo de manera rapida y eficiente, mientras que Sass, una extensión de
					CSS, añade características como variables, anidación y mixins, lo que hace que
					escribir y mantener hojas de estilo sea mucho más sencillo. Al combinar estas
					tecnologías, los desarrolladores pueden crear interfaces de usuario
					personalizadas, escalables y altamente performantes, mejorando
					significativamente la experiencia del usuario.
				</p>
				<h3>Desarrollo de APIs</h3>
				<p>
					Mediante la poderosa combinación de MongoDB, TypeScript, Express y Node.js, se
					pueden construir APIs robustas, escalables y altamente eficientes. Node.js, como
					entorno de ejecución de JavaScript del lado del servidor, proporciona una base
					sólida para el desarrollo de aplicaciones web / APIs. Express, un framework
					minimalista para Node.js, simplifica la creación de rutas y el manejo de
					solicitudes HTTP. TypeScript, un superconjunto tipado de JavaScript, añade
					seguridad y estructura al código, facilitando la detección temprana de errores.
					MongoDB, una base de datos NoSQL altamente flexible, permite almacenar y
					gestionar grandes volúmenes de datos de manera eficiente. Esta sinergia de
					tecnologías permite a los desarrolladores crear APIs personalizadas y adaptables
					a las necesidades de cualquier aplicación moderna.
				</p>
				<h3>GIT</h3>
				<p>
					Git es un sistema de control de versiones distribuido, ampliamente utilizado en
					el desarrollo de software. Su principal función es rastrear los cambios
					realizados en un conjunto de archivos a lo largo del tiempo, permitiendo así
					gestionar de manera eficiente la evolución de un proyecto y llevar un control de
					versiones distribuido en ramas, cada rama dependiendo de la implementacion o
					caracteristicas a implementar.
				</p>
			</div>
			<br />	
		</>
	);
}

export default MyStack;
